.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}