.chat-container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  background-color: #FFF;
  margin: auto;
}

/* chat-input-container {
  margin: 40px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the input container */
  box-sizing: border-box;
}

.chat-input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
}

.send-button {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #064e44;
}

.chat-widget {
  width: 100%;
  height: 100vh;
  background-color: #FFF;
}

.rce-mbox-text {
  font-size: 16px;
}

.overflow {
  overflow-y: auto;
  flex: 1;
}

.message-list {
  width: 64%;
  margin-left: 18%;
}

.lia-header {
  text-align: center;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-weight: 600;
  color: white;
  background-color: #0F1E37;
  border-bottom: 1px solid #ccc;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: #ccc;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  color: #075e54; /* WhatsApp green */
  cursor: pointer;
}

.chat-container,
.message-list {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f0f0f0;
}

.message-list::-webkit-scrollbar {
  width: 8px;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.message-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .chat-container {
    width: 90%;
    min-width: 300px;
    height: 600px;
    border-radius: 0;
  }

  .chat-input-container{
    position: fixed;
    bottom: 0;
  }

  .chat-header {
    width: 100%;
  }
}

.loading {
  font-size: 16px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}